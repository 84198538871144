import { render } from "./order.vue?vue&type=template&id=3e99b9ce&scoped=true"
import script from "./order.vue?vue&type=script&lang=js"
export * from "./order.vue?vue&type=script&lang=js"

import "./order.vue?vue&type=style&index=0&id=3e99b9ce&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-3e99b9ce"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3e99b9ce"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3e99b9ce', script)) {
    api.reload('3e99b9ce', script)
  }
  
  module.hot.accept("./order.vue?vue&type=template&id=3e99b9ce&scoped=true", () => {
    api.rerender('3e99b9ce', render)
  })

}

script.__file = "src/views/shop/order/order.vue"

export default script