<template>
  <div class="wrapper">
<!--    提交订单页面-->
    <headBack>
      <template v-slot:title>
        <div class='title'>
           确认订单
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <div class="order_box">
     <div class="n_box" @click="addAddress" v-if="!route.query.address">
       <van-icon name="location-o" size="30"/>
       <div class="txt"> 快来选择个收货地址吧 ~ </div>
     </div>
      <div v-else class="address_box" @click="addAddress">
        <div>
          <div><span class="name">{{route.query.name}}</span><span class="phone">{{setTel(route.query.tel)}}</span></div>
          <div class="address">{{route.query.address}}</div>
        </div>
        <van-icon name="arrow" size="22" color="#999"/>
      </div>
      <div class="line"></div>
      <div class="card_box">
        <div v-for="(item,index) in lists">
          <van-card
              :num="item.num"
              :price="item.price"
              desc=""
              :title="item.name"
              :thumb="item.img"
          />
        </div>
      </div>
      <div class="total_box">
           <div class="tot">
               <span>商品金额</span>
               <span>￥<span>{{listsPrice}}</span></span>
           </div>
          <div class="yf">
            <span>运费</span>
            <span>￥<span>{{freightPrice}}</span></span>
          </div>
      </div>
      <div class="total_box">

          <div>选择支付方式</div>
        <van-radio-group v-model="checked">
          <van-radio name="1" icon-size="15" checked-color="#00c291"><div class="zf"><img src="https://static.yihu365.cn/img/h5Img/shop/wxin2.png" alt=""><span>微信</span></div></van-radio>
<!--          <van-radio disabled name="2" icon-size="15" checked-color="#00c291"><div class="zf"><img src="https://static.yihu365.cn/img/h5Img/shop/zfb_hui.png" alt=""><span>支付宝</span></div></van-radio>-->
        </van-radio-group>

      </div>
    </div>
    <div class="bottom_fixed">
<!--      <div class="kf">-->
<!--        <van-checkbox v-model="checked" checked-color="#ee0a24" icon-size="16">全选</van-checkbox>-->
<!--      </div>-->
      <div class="gow">
        <div class="all_p">总计：<span>¥</span><span class="num">{{allTotal.toFixed(2)}}</span></div>
        <div class="n_txt">共{{allNums}}件</div>
      </div>

      <button class="b_btn" @click="submitOrder">支付订单</button>
    </div>

  </div>

</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive, watch} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'
import loginApi from '@axios/login'
export default {
  name: "order",
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const lists = ref([])
    const allNums = ref('');
    const listsPrice = ref('');
    const checked = ref('1');
    const addressId = ref('');
    const freightPrice = ref(10.00)
    const addAddress =()=> {
      router.push({
        name:'addressList'
      })
    }
    // const address = route.query.adDetail
    // console.log(address);

    const setList = (list) =>{
      const arr = []
     list.forEach((item,index)=> {
        let obj ={
          img:item.iconUrl,
          name:item.goodsName,
          price:item.price,
          num:item.goodsNum
        }
        arr.push(obj)
      });
     return arr;
    }
    const orderData = JSON.parse(sessionStorage.getItem('orderData'));
    lists.value = setList(orderData.list)
    allNums.value=orderData.allNums;
    listsPrice.value =orderData.total;

    const allTotal = computed(()=> {
      return Number(listsPrice.value) +Number(freightPrice.value)
    })

    addressId.value = route.query.addressId
    const getFreightPrice = () =>{//获取运费
      const params ={
        addressId:addressId.value,
        orderPriceSum:listsPrice.value,
        userId:store.state.uid
      }
      shopApi.getFreightPrice(params).then((res)=> {
        console.log(res)
        freightPrice.value = res.data.freightPrice
      })
    }
    getFreightPrice();
    const submitOrder = ()=> {//提交订单
      if(!addressId.value){
        Toast("请选择地址")
        return;
      }
        const params = {
          freightPrice:freightPrice.value,
          type : route.query.type ?  route.query.type : '2',
          addressId : addressId.value,
          orderPriceSum:listsPrice.value,
          userId:store.state.uid,
          orderData :orderData.list,
          sid : store.state.sid,
      }
        console.log(params)
      shopApi.getOrderSubmit(params).then((res)=> {
        // console.log(res)
        if(res.code == '0000'){
          chosePayment(res.data.orderId);
        }
      })
    }
    //支付
    let isIOS= !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
   const payH5 = (orderId) => {
     const source = store.state.sourceMark;
     let formData = new FormData();
     let redirectUrl ='';
     if(source == '001'){//001是安卓和IOS
       redirectUrl = encodeURIComponent("http://m.yihu365.cn/public.shtml")
     }else if(source == '002'){//002 是IOS医护端
       redirectUrl = encodeURIComponent("http://www.yihu365.cn/app")
     }else{
       redirectUrl = encodeURIComponent("https://mi.yihu365.cn/shop")
     }

     formData.append('data',JSON.stringify({
       function : 'rechargeOnline',
       source : 'h5_users_002',
       user_id : store.state.uid,
       fee : listsPrice.value + '',
       pay_type : '261',
       voucherId : "",
       // order_id : route.query.orderId,
       orderId : orderId,
       gate_id : '1',
       app_flag : '3',
       device_id : 'h5',
       h5PayReturnUrl:redirectUrl,
       order_type:'30',
       _from : 'h5',
       token : store.state.token,
       userid : store.state.uid,
       userId : store.state.uid,
       version : store.state.version,
       newversion : store.state.newversion,
       sid : store.state.sid,
     }))
     formData.append('encryption',false)
     loginApi.loginAction(formData).then((res)=>{
       console.log(res.data)

        window.location.href = res.data;

     })
   }
   const payH5Wx = (orderId) => {//微信环境支付
     let formData = new FormData();

     formData.append('data',JSON.stringify({
       function : 'rechargeOnline',
       source : 'h5_users_wx_101',
       user_id : store.state.uid,
       fee : listsPrice.value + '',
       pay_type : '45',
       gate_id : '-1',
       voucherId : "",
       sid : store.state.sid,
       app_flag : '3',
       device_id : 'h5',
       orderId : orderId,
       _from : 'h5',
       token : store.state.token,
       userid : store.state.uid,
       userId : store.state.uid,
       version : store.state.version,
       newversion : store.state.newversion,
       openid : store.state.openId,
       order_type:'30',
     }))

     formData.append('encryption',false)
     loginApi.loginAction(formData).then((res)=>{
       let result = JSON.parse(res.data)
       WeixinJSBridge.invoke(
           'getBrandWCPayRequest', {
             "appId":result.appId,     //公众号ID，由商户传入
             "timeStamp":result.timeStamp,         //时间戳，自1970年以来的秒数
             "nonceStr":result.nonceStr, //随机串
             "package":result.package,
             "signType":result.signType,         //微信签名方式：
             "paySign":result.paySign //微信签名
           },
           function(res){
             res.err_msg = undefined;
             if(res.err_msg == "get_brand_wcpay_request:ok" ){
               router.push({
                 name : 'success'
               })
           }
             if(res.err_msg == "get_brand_wcpay_request:cancel" ){
               router.push({
                 name : 'shopOrder'
               })
             }
             if(res.err_msg == "get_brand_wcpay_request:fail" ){
               router.push({
                 name : 'shopOrder'
               })
             }
           }
       );
     })


   }
    onMounted(()=> {

    })
    const isWx = () =>{
      let ua = window.navigator.userAgent.toLowerCase();
      return (ua.match(/MicroMessenger/i) == 'micromessenger') ? true : false;
    }
    const chosePayment = (orderId) => {
      if(isWx()){//微信浏览器
        //小程序环境判断
        wx.miniProgram.getEnv((res)=>{
          if(res.miniprogram){

            // alert("在小程序里123")
            wx.miniProgram.navigateTo({
              url: `/pages/shop-webview/pay/pay?orderId=${orderId}&fee=${listsPrice.value}&orderType=30&fromPage=2`, //fromPage=2代表商城页面 如果需要传递参数的话可以通过 url 路径拼接
              success: (result)=> {
                // alert(JSON.stringify(result))
              },
              fail:(err) => {
                 alert("打开失败")
              }
            });
            return;

          }else{
            payH5Wx(orderId);//微信公众号支付
          }
        })

      }else{//非微信浏览器
        payH5(orderId)
      }
    }
    const setTel = (tel) => {
      let reg = /^(\d{3})\d{4}(\d{4})$/;
      return tel.replace(reg, "$1****$2");

    }
    return {
      lists,
      checked,
      addAddress,
      route,
      listsPrice,
      allNums,
      freightPrice,
      allTotal,
      submitOrder,
      setTel
    }
  }

}
</script>

<style scoped lang="scss">
.wrapper{
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 100px;
  background:#f2f4f5;
  ::v-deep(.van-card__title){
    font-size: 30px;
  }
}
.n_box{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #FFFFFF;
  padding:44px 40px;
  color:#666;
  .txt{
    margin-left: 10px;
    font-size: 32px;
  }
}
.address_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  padding:40px 40px;
  color:#1a1a1a;
  .name{
    font-size: 34px;
    font-weight: 500;
  }
  .phone{
    font-size: 30px;
    margin-left: 20px;
    color:#666;
  }
  .address{
    color:#999999;
  }
}
.line{
  margin-bottom: 20px;
  width: 100%;
  height: 5px;
  background: -webkit-repeating-linear-gradient(135deg,#1E9CD9 0,#1E9CD9 20%,transparent 0,transparent 25%,#00c291 0,#00c291 45%,transparent 0,transparent 50%);
  background-size:180px;

}

.bottom_fixed{
  color:#262626;
  width: 100%;
  height: 120px;
  position: fixed;
  left:0;
  bottom:0;
  border-top:1px solid #eee;
  background: #fff;
  display: flex;
  font-size: 26px;
  align-items: center;
  box-sizing: border-box;
  padding-left:40px;

  .kf{
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }
  .gow{
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    .all_p{
      color:#FF4A26;
      .num{
        font-size: 37px;
      }
    }
    .n_txt{
      color:#666666;
    }
  }
  button{
    text-align: center;
    width: 240px;
    border:none;
    height:100px;
    font-size: 28px;
    color:#fff;
  }

  .b_btn{
    font-size: 32px;
    margin-right: 30px;
    border-radius: 40px;
    height:74px;
    background: linear-gradient(to bottom right, #25c011 0%, #00c291 100%);
  }
}
.order_box{
  color:#323233;
  padding-bottom: 140px;
  background: #f2f4f5;
  font-size: 28px;
   font-family: Avenir-Heavy,PingFang SC,Helvetica Neue,Arial,sans-serif;
  .card_box{
    box-sizing: border-box;
    width: 94%;
    margin:30px auto 0;
    background: #FFFFFF;
    border-radius: 12px;
    ::v-deep(.van-card){
      padding:20px;
      border-radius: 12px;
      background: #FFFFFF;
    }
  }
  .total_box{
    box-sizing: border-box;
    margin:30px auto 0;
    width: 94%;
    color:#1a1a1a;
    padding:36px 30px;
    background: #FFFFFF;
    border-radius: 12px;

    .tot{
       display: flex;
      justify-content: space-between;
    }
    .yf{
      margin-top: 12px;
       display: flex;
      justify-content: space-between;
    }
    .zf{
      display: flex;
      align-items: center;
       img{
         width: 35px;
         height: 35px;
         margin-right: 6px;
       }

    }
    .van-radio{
      margin-top: 12px;
    }
  }
}
</style>
